import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { setAmountToPay } from "../../../../data-store/actions/vehicle-actions";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function BillingConfirmation(props) {
  const { callback, billingData } = props;
  const dispatch = useDispatch();

  const classes = useStyles();

  function decodeBase64(encodedText) {
    try {
      // Decodificar el texto en Base64
      const decodedText = atob(encodedText);

      // Imprimir el resultado en la consola
      console.log("Texto decodificado:", decodedText);

      return decodedText;
    } catch (error) {
      console.error("Error al decodificar Base64:", error);
      return null;
    }
  }

  if (
    billingData &&
    billingData.resp &&
    billingData.resp.InformacionAdicional &&
    billingData.resp.InformacionAdicional.UrlQRCode &&
    billingData.resp.MensajeError === undefined
  ) {
    return (
      <Grid
        container
        direction="column"
        style={{
          height: "auto",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          alignItems={"center"}
          style={{ margin: "40px" }}
        >
          <Button
            href={decodeBase64(billingData.resp.InformacionAdicional.UrlQRCode)}
            target="_blank"
            color="primary"
            variant="contained"
            style={{ padding: 30, width: 400, fontSize: 20 }}
          >
            Ver factura
          </Button>
        </Grid>
      </Grid>
    );
  } else if (
    billingData &&
    billingData.resp &&
    billingData.billRequested &&
    billingData.resp.MensajeError !== undefined
  ) {
    return (
      <Grid
        container
        direction="column"
        style={{
          height: "auto",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          alignItems={"center"}
          style={{ margin: "40px" }}
        >
          <Typography
            color="primary"
            variant="h4"
            style={{ marginTop: 40, padding: 10 }}
          >
            Error generando factura favor comunicarse al teléfono 398-8888
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      style={{
        height: "auto",
        width: "100%",
      }}
    ></Grid>
  );
}
