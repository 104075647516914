import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, dispatch, useDispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";
import CardPayment from "./CardPayment";
import { Button, Paper } from "@material-ui/core";
import { getVehiclePayment } from "../../../../data-store/actions/vehicle-actions";
import YappyPayment from "./YappyPayment";
import BillingForm from "./BillingForm";
import BillingFormSlim from "./BillingFormSlim";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function PaymentContainer(props) {
  const { businessId, locationId, card, vehicleId, textColor } = props;
  const [show, setShow] = useState(false);
  const [billingData, setBillingData] = useState(false);

  const dispatch = useDispatch();

  const amountToPay = useSelector((state) => state.vehicle.amountToPay);
  const dateToPay = useSelector((state) => state.vehicle.dateToPay);
  const amountPayed = useSelector((state) => state.vehicle.amountPayed);
  const statusPayment = useSelector((state) => state.vehicle.statusPayment);

  function showPayment() {
    setShow(!show);

    dispatch(getVehiclePayment(businessId, locationId, card));
  }
  return (
    <Grid container direction="row">
      {!show && (
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="primary"
            style={{ padding: 40, fontSize: 30 }}
            onClick={() => showPayment()}
          >
            Realizar pago en línea
          </Button>
        </Grid>
      )}
      {show && (
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="primary"
            style={{ padding: 40, fontSize: 30 }}
            onClick={() => setShow(false)}
          >
            Cerrar vista de pago
          </Button>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      >
        {!amountToPay && amountToPay !== 0 && show && (
          <Typography color="primary" variant="h3" style={{ marginTop: 40 }}>
            Cargando monto a pagar...
          </Typography>
        )}

        {amountToPay >= 0 && show && (
          <Typography color="primary" variant="h3" style={{ marginTop: 40 }}>
            Total a pagar: {amountToPay && amountToPay} USD
          </Typography>
        )}

        <Grid container direction="row">
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: "10px", textAlign: "center" }}
          >
            {amountToPay >= 0 && show && (
              <BillingForm
                callback={setBillingData}
                billingData={billingData}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: "10px", textAlign: "center" }}
          >
            {amountToPay >= 0 &&
              show &&
              (businessId === "idbusiness_2" ||
                businessId === "kmq9dvl0EYjyhHPTCRfi") && (
                <BillingFormSlim
                  callback={setBillingData}
                  billingData={billingData}
                />
              )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12}>
        {show && (
          <YappyPayment
            locationId={locationId}
            businessId={businessId}
            vehicleId={vehicleId}
            card={card}
            billingData={billingData}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12}>
        {show && (
          <Paper>
            <CardPayment
              locationId={locationId}
              businessId={businessId}
              vehicleId={vehicleId}
              card={card}
              billingData={billingData}
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
