import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import EventIcon from "@material-ui/icons/Event";
//import UserPaymentMethodsAdd from "../UserProfile/UserPaymentMethodsAdd";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { setAmountToPay } from "../../../../data-store/actions/vehicle-actions";
import logoYappy from "./LogoYappy3alta.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function YappyPayment(props) {
  const {
    callback,
    locationId,
    businessId,
    vehicleId,
    card,
    billingData,
  } = props;
  const dispatch = useDispatch();

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState(false);
  const [successURL, setSuccessURL] = useState(false);

  const amountToPay = useSelector((state) => state.vehicle.amountToPay);
  const dateToPay = useSelector((state) => state.vehicle.dateToPay);
  const amountPayed = useSelector((state) => state.vehicle.amountPayed);
  const statusPayment = useSelector((state) => state.vehicle.statusPayment);
  const currentVehicle = useSelector((state) => state.vehicle.currentVehicle);

  const location = useLocation();
  const { hash, pathname, search } = location;
  console.log("query", pathname, search);

  // const businessId = query.get("business");
  // const locationId = query.get("location");
  // const card = query.get("card");

  useEffect(() => {
    // yappy.setButton(false, "formId", "brand");
    // //dispatch event
    // window.document.dispatchEvent(
    //   new Event("DOMContentLoaded", {
    //     bubbles: true,
    //     cancelable: true,
    //   })
    // );
  }, []);

  function onSubmit(data) {
    // setCardNumber(data.cardNumber);
    // setCardOwner(data.cardOwner);
    setSending(true);
    seterrorMessage(false);
    // console.log("data", data);
    // console.log("data", cardNumber);
    // console.log("data", sending);

    console.log("tokenizar", data);
    sendPaymetYappy(data);
  }

  function sendPaymetYappy(data) {
    setLoading(true);

    let tel = "";
    if (
      currentVehicle &&
      currentVehicle.phoneNumber &&
      currentVehicle.phoneNumber.length === 11
    ) {
      tel = currentVehicle.phoneNumber.substr(-8, 8);
    }
    const payment = {
      total: amountToPay,
      subtotal: amountToPay,
      shipping: 0.0,
      discount: 0.0,
      taxes: "0.00",
      orderId: vehicleId.split("-")[1],
      successUrl:
        "https://smartvalet.app" + pathname + search + "&yappysuccess=",
      failUrl:
        "https://smartvalet.app" + pathname + search + "&yappysuccess=false",
      tel: tel,
      domain: "https://smartvalet.app",
    };

    var jsonDatos = {
      businessId: businessId,
      locationId: locationId,
      vehicleId: vehicleId,
      date: dateToPay,
      payment: payment,
      billingData: billingData,
    };

    console.log("payment", jsonDatos);

    console.log(jsonDatos);

    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    axios
      .post(
        "https://us-central1-softicade.cloudfunctions.net/setYappyPayment",
        jsonDatos,
        headers
      )
      .then((res) => {
        console.log("respuesta", res);

        setSending(false);

        if (res.data.success === true) {
          console.log(
            "respuesta",
            "Aprobada ahora redirige a la web de Yappy" + res.data.url
          );
          seterrorMessage("Aprobada ahora redirige a la web de Yappy");
          setSuccessURL(res.data.url);

          window.location.href = res.data.url;
        } else {
          seterrorMessage(
            "Transacción declinada" + JSON.stringify(res.data.error.message)
          );
        }
      })
      .catch((error) => {
        console.log("respuesta", JSON.stringify(error));
        setLoading(false);
        seterrorMessage("Error procesando el pago");
        var errorTexto = "";
        if (error.responseJSON !== undefined) {
          for (var i in error.responseJSON.details) {
            errorTexto =
              errorTexto + error.responseJSON.details[i].message + " - ";
          }
        }
      });
  }

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid item xs={12} sm={12} alignItems={"left"} style={{ margin: "40px" }}>
        {amountToPay === 0 && amountPayed > 0 && statusPayment === 1 && (
          <Typography
            color="primary"
            variant="h4"
            style={{ marginTop: 40, padding: 10 }}
          >
            Pago éxitoso, diríjase a retirar su vehículo.
          </Typography>
        )}

        <Button
          variant="contained"
          style={{
            padding: 20,
            width: 400,
            backgroundColor: "#0a4697",
            color: "#ffffff",
          }}
          onClick={() => sendPaymetYappy()}
          disabled={loading}
        >
          {loading && "Enviando..."}
          {!loading && <img src={logoYappy} alt="banner" width={"100%"} />}
        </Button>
      </Grid>
    </Grid>
  );
}
