import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { setAmountToPay } from "../../../../data-store/actions/vehicle-actions";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function BillingForm(props) {
  const { callback, billingData } = props;
  const dispatch = useDispatch();

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState(false);
  const [showForm, setShowForm] = useState(false);

  function onSubmit(data) {
    // setCardNumber(data.cardNumber);
    // setCardOwner(data.cardOwner);
    seterrorMessage(false);
    // console.log("data", data);
    // console.log("data", cardNumber);
    // console.log("data", sending);
    setShowForm(false);
    callback(data);
  }

  const [formData, setFormData] = useState({
    type: "",
    businessName: "",
    ruc: "",
    dv: "",
    phone: "",
    email: "",
    province: "",
    district: null,
    corregimiento: null,
    direction: "",
    codigoUbicacion: "",
  });

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    if (
      e.target.name === "province" &&
      formData.province !== "" &&
      formData.district !== ""
    ) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        district: null,
        corregimiento: null,
      });
    } else if (e.target.name === "district" && formData.district !== "") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        corregimiento: null,
      });
    } else if (e.target.name === "corregimiento") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        codigoUbicacion:
          locations[formData.province][formData.district][e.target.value].code,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    callback(formData);
    setShowForm(false);
  };

  function decodeBase64(encodedText) {
    try {
      // Decodificar el texto en Base64
      const decodedText = atob(encodedText);

      // Imprimir el resultado en la consola
      console.log("Texto decodificado:", decodedText);

      return decodedText;
    } catch (error) {
      console.error("Error al decodificar Base64:", error);
      return null;
    }
  }

  if (!showForm) {
    return (
      <Grid
        container
        direction="row"
        style={{
          height: "auto",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          alignItems={"left"}
          style={{ margin: "40px" }}
        >
          <Button
            onClick={() => setShowForm(true)}
            color="primary"
            variant="contained"
            style={{ padding: 30, width: 400, fontSize: 15 }}
          >
            <Grid container direction="row">
              <Grid item xs={12} sm={12}>
                Pedir Factura Personalizada (Todos los datos)
              </Grid>
              <Grid item xs={12} sm={12}></Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        alignItems={"center"}
        style={{ margin: "40px" }}
      >
        <Button
          onClick={() => setShowForm(false)}
          color="primary"
          variant="contained"
          style={{ padding: 30, width: 400, fontSize: 20 }}
        >
          Ocultar formulario de facturación
        </Button>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel>Tipo de Persona</InputLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <MenuItem value="1">Persona Natural</MenuItem>
                <MenuItem value="2">Empresa (Contribuyente)</MenuItem>
                <MenuItem value="3">Gobierno</MenuItem>
                <MenuItem value="4">Extranjero</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {formData.type === "2" && (
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Tipo de cliente</InputLabel>
                <Select
                  name="clientType"
                  value={formData.clientType}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="01">Natural</MenuItem>
                  <MenuItem value="02">Jurídico</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Nombre / Razón Social"
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="RUC"
              name="ruc"
              value={formData.ruc}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Dígito Verificador"
              name="dv"
              value={formData.dv}
              onChange={handleChange}
              required
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Teléfono"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Correo Electrónico"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Provincia</InputLabel>
              <Select
                name="province"
                value={formData.province}
                onChange={handleChange}
                required
              >
                <MenuItem key={""} value={null}>
                  Seleccionar
                </MenuItem>

                {Object.keys(locations).map((prov) => (
                  <MenuItem key={prov} value={prov}>
                    {prov}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Distrito</InputLabel>
              <Select
                name="district"
                value={formData.district}
                onChange={handleChange}
                disabled={!formData.province}
                required
              >
                <MenuItem key={""} value={null}>
                  Seleccionar
                </MenuItem>
                {formData.province !== "" &&
                  locations[formData.province] &&
                  Object.keys(locations[formData.province]).map((dist) => (
                    <MenuItem key={dist} value={dist}>
                      {dist}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Corregimiento</InputLabel>
              <Select
                name="corregimiento"
                value={formData.corregimiento}
                onChange={handleChange}
                disabled={!formData.district}
                required
              >
                <MenuItem key={""} value={null}>
                  Seleccionar
                </MenuItem>
                {formData.province &&
                  formData.district &&
                  locations[formData.province][formData.district] &&
                  Object.keys(
                    locations[formData.province][formData.district]
                  ).map((corr) => (
                    <MenuItem key={corr} value={corr}>
                      {corr}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Dirección"
              name="direction"
              value={formData.direction}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Guardar Datos
            </Button>
          </Grid>
        </Grid>
      </form>{" "}
    </Grid>
  );
}

const locations = {
  "BOCAS DEL TORO": {
    "BOCAS DEL TORO": {
      "BOCAS DEL TORO (CABECERA)": {
        code: "1-1-1",
      },
      BASTIMENTOS: {
        code: "1-1-2",
      },
      CAUCHERO: {
        code: "1-1-3",
      },
      "PUNTA LAUREL": {
        code: "1-1-4",
      },
      "TIERRA OSCURA": {
        code: "1-1-5",
      },
    },
    CHANGUINOLA: {
      "CHANGUINOLA (CABECERA)": {
        code: "1-2-1",
      },
      ALMIRANTE: {
        code: "1-2-2",
      },
      GUABITO: {
        code: "1-2-3",
      },
      TERIBE: {
        code: "1-2-4",
      },
      "VALLE DEL RISCO": {
        code: "1-2-5",
      },
      "EL EMPALME": {
        code: "1-2-6",
      },
      "LAS TABLAS": {
        code: "1-2-7",
      },
    },
    "CHIRIQUI GRANDE": {
      "CHIRIQUI GRANDE (CABECERA)": {
        code: "1-3-1",
      },
      MIRAMAR: {
        code: "1-3-2",
      },
      "PUNTA PEÑA": {
        code: "1-3-3",
      },
      "PUNTA ROBALO": {
        code: "1-3-4",
      },
      RAMBALA: {
        code: "1-3-5",
      },
    },
  },
  COCLE: {
    AGUADULCE: {
      "AGUADULCE (CABECERA)": {
        code: "2-1-1",
      },
      "EL CRISTO": {
        code: "2-1-2",
      },
      "EL ROBLE": {
        code: "2-1-3",
      },
      POCRI: {
        code: "2-1-4",
      },
      "BARRIOS UNIDOS": {
        code: "2-1-5",
      },
    },
    ANTON: {
      "ANTON (CABECERA)": {
        code: "2-2-1",
      },
      CABUYA: {
        code: "2-2-2",
      },
      "EL CHIRU": {
        code: "2-2-3",
      },
      "EL RETIRO": {
        code: "2-2-4",
      },
      "EL VALLE": {
        code: "2-2-5",
      },
      "JUAN DIAZ": {
        code: "2-2-6",
      },
      "RIO HATO": {
        code: "2-2-7",
      },
      "SAN JUAN DE DIOS": {
        code: "2-2-8",
      },
      "SANTA RITA": {
        code: "2-2-9",
      },
      CABALLERO: {
        code: "2-2-10",
      },
    },
    "LA PINTADA": {
      "LA PINTADA (CABECERA)": {
        code: "2-3-1",
      },
      "EL HARINO": {
        code: "2-3-2",
      },
      "EL POTRERO": {
        code: "2-3-3",
      },
      "LLANO GRANDE": {
        code: "2-3-4",
      },
      "PIEDRAS GORDAS": {
        code: "2-3-5",
      },
      "LAS LOMAS": {
        code: "2-3-6",
      },
    },
    NATA: {
      "NATA (CABECERA)": {
        code: "2-4-1",
      },
      CAPELLANIA: {
        code: "2-4-2",
      },
      "EL CAÑO": {
        code: "2-4-3",
      },
      GUZMAN: {
        code: "2-4-4",
      },
      "LAS HUACAS": {
        code: "2-4-5",
      },
      TOZA: {
        code: "2-4-6",
      },
    },
    OLA: {
      "OLA (CABECERA)": {
        code: "2-5-1",
      },
      "EL COPE": {
        code: "2-5-2",
      },
      "EL PALMAR": {
        code: "2-5-3",
      },
      "EL PICACHO": {
        code: "2-5-4",
      },
      "LA PAVA": {
        code: "2-5-5",
      },
    },
    PENONOME: {
      "PENONOME (CABECERA)": {
        code: "2-6-1",
      },
      CAÑAVERAL: {
        code: "2-6-2",
      },
      COCLE: {
        code: "2-6-3",
      },
      "CHIGUIRI ARRIBA": {
        code: "2-6-4",
      },
      "EL COCO": {
        code: "2-6-5",
      },
      PAJONAL: {
        code: "2-6-6",
      },
      "RIO GRANDE": {
        code: "2-6-7",
      },
      "RIO INDIO": {
        code: "2-6-8",
      },
      TOABRE: {
        code: "2-6-9",
      },
      TULU: {
        code: "2-6-10",
      },
    },
  },
  COLON: {
    COLON: {
      "BARRIO NORTE": {
        code: "3-1-1",
      },
      "BARRIO SUR": {
        code: "3-1-2",
      },
      "BUENA VISTA": {
        code: "3-1-3",
      },
      CATIVA: {
        code: "3-1-4",
      },
      CIRICITO: {
        code: "3-1-5",
      },
      CRISTOBAL: {
        code: "3-1-6",
      },
      ESCOBAL: {
        code: "3-1-7",
      },
      LIMON: {
        code: "3-1-8",
      },
      "NUEVA PROVIDENCIA": {
        code: "3-1-9",
      },
      "PUERTO PILON": {
        code: "3-1-10",
      },
      SABANITAS: {
        code: "3-1-11",
      },
      SALAMANCA: {
        code: "3-1-12",
      },
      "SAN JUAN": {
        code: "3-1-13",
      },
      "SANTA ROSA": {
        code: "3-1-14",
      },
    },
    CHAGRES: {
      "NUEVO CHAGRES (CABECERA)": {
        code: "3-2-1",
      },
      ACHIOTE: {
        code: "3-2-2",
      },
      "EL GUABO": {
        code: "3-2-3",
      },
      "LA ENCANTADA": {
        code: "3-2-4",
      },
      "PALMAS BELLAS": {
        code: "3-2-5",
      },
      PIÑA: {
        code: "3-2-6",
      },
      SALUD: {
        code: "3-2-7",
      },
    },
    DONOSO: {
      "MIGUEL DE LA BORDA (CABECERA)": {
        code: "3-3-1",
      },
      "COCLE DEL NORTE": {
        code: "3-3-2",
      },
      "EL GUASIMO": {
        code: "3-3-3",
      },
      GOBEA: {
        code: "3-3-4",
      },
      "RIO INDIO": {
        code: "3-3-5",
      },
      "SAN JOSE DEL GENERAL": {
        code: "3-3-6",
      },
    },
    PORTOBELO: {
      "PORTOBELO (CABECERA)": {
        code: "3-4-1",
      },
      CACIQUE: {
        code: "3-4-2",
      },
      GARROTE: {
        code: "3-4-3",
      },
      "ISLA GRANDE": {
        code: "3-4-4",
      },
      "MARIA CHIQUITA": {
        code: "3-4-5",
      },
    },
    "SANTA ISABEL": {
      "PALENQUE (CABECERA)": {
        code: "3-5-1",
      },
      CUANGO: {
        code: "3-5-2",
      },
      MIRAMAR: {
        code: "3-5-3",
      },
      "NOMBRE DE DIOS": {
        code: "3-5-4",
      },
      PALMIRA: {
        code: "3-5-5",
      },
      "PLAYA CHIQUITA": {
        code: "3-5-6",
      },
      "SANTA ISABEL": {
        code: "3-5-7",
      },
      "VIENTO FRIO": {
        code: "3-5-8",
      },
    },
  },
  CHIRIQUI: {
    ALANJE: {
      "ALANJE (CABECERA)": {
        code: "4-1-1",
      },
      DIVALA: {
        code: "4-1-2",
      },
      "EL TEJAR": {
        code: "4-1-3",
      },
      GUARUMAL: {
        code: "4-1-4",
      },
      "PALO GRANDE": {
        code: "4-1-5",
      },
      QUEREVALO: {
        code: "4-1-6",
      },
      "SANTO TOMAS": {
        code: "4-1-7",
      },
      "CANTA GALLO": {
        code: "4-1-8",
      },
      "NUEVO MEXICO": {
        code: "4-1-9",
      },
    },
    BARU: {
      "PUERTO ARMUELLES (CABECERA)": {
        code: "4-2-1",
      },
      LIMONES: {
        code: "4-2-2",
      },
      PROGRESO: {
        code: "4-2-3",
      },
      BACO: {
        code: "4-2-4",
      },
      "RODOLFO AGUILAR DELGADO": {
        code: "4-2-5",
      },
    },
    BOQUERON: {
      "BOQUERON (CABECERA)": {
        code: "4-3-1",
      },
      BAGALA: {
        code: "4-3-2",
      },
      CORDILLERA: {
        code: "4-3-3",
      },
      GUABAL: {
        code: "4-3-4",
      },
      GUAYABAL: {
        code: "4-3-5",
      },
      PARAISO: {
        code: "4-3-6",
      },
      PEDREGAL: {
        code: "4-3-7",
      },
      TIJERAS: {
        code: "4-3-8",
      },
    },
    BOQUETE: {
      "BAJO BOQUETE (CABECERA)": {
        code: "4-4-1",
      },
      CALDERA: {
        code: "4-4-2",
      },
      PALMIRA: {
        code: "4-4-3",
      },
      "ALTO BOQUETE": {
        code: "4-4-4",
      },
      JARAMILLO: {
        code: "4-4-5",
      },
      "LOS NARANJOS": {
        code: "4-4-6",
      },
    },
    BUGABA: {
      "LA CONCEPCION (CABECERA)": {
        code: "4-5-1",
      },
      "ASERRIO DE GARICHE": {
        code: "4-5-2",
      },
      BUGABA: {
        code: "4-5-3",
      },
      "CERRO PUNTA": {
        code: "4-5-4",
      },
      GOMEZ: {
        code: "4-5-5",
      },
      "LA ESTRELLA": {
        code: "4-5-6",
      },
      "SAN ANDRES": {
        code: "4-5-7",
      },
      "SANTA MARTA": {
        code: "4-5-8",
      },
      "SANTA ROSA": {
        code: "4-5-9",
      },
      "SANTO DOMINGO": {
        code: "4-5-10",
      },
      SORTOVA: {
        code: "4-5-11",
      },
      VOLCAN: {
        code: "4-5-12",
      },
      "EL BONGO": {
        code: "4-5-13",
      },
    },
    DAVID: {
      "DAVID (CABECERA)": {
        code: "4-6-1",
      },
      BIJAGUAL: {
        code: "4-6-2",
      },
      COCHEA: {
        code: "4-6-3",
      },
      CHIRIQUI: {
        code: "4-6-4",
      },
      GUACA: {
        code: "4-6-5",
      },
      "LAS LOMAS": {
        code: "4-6-6",
      },
      PEDREGAL: {
        code: "4-6-7",
      },
      "SAN CARLOS": {
        code: "4-6-8",
      },
      "SAN PABLO NUEVO": {
        code: "4-6-9",
      },
      "SAN PABLO VIEJO": {
        code: "4-6-10",
      },
    },
    DOLEGA: {
      "DOLEGA (CABECERA)": {
        code: "4-7-1",
      },
      "DOS RIOS": {
        code: "4-7-2",
      },
      "LOS ANASTACIOS": {
        code: "4-7-3",
      },
      POTRERILLOS: {
        code: "4-7-4",
      },
      "POTRERILLOS ABAJO": {
        code: "4-7-5",
      },
      ROVIRA: {
        code: "4-7-6",
      },
      TINAJAS: {
        code: "4-7-7",
      },
      "LOS ALGARRROBOS": {
        code: "4-7-8",
      },
    },
    GUALACA: {
      "GUALACA (CABECERA)": {
        code: "4-8-1",
      },
      HORNITO: {
        code: "4-8-2",
      },
      "LOS ANGELES": {
        code: "4-8-3",
      },
      "PAJA DE SOMBRERO": {
        code: "4-8-4",
      },
      RINCON: {
        code: "4-8-5",
      },
    },
    REMEDIOS: {
      "REMEDIOS (CABECERA)": {
        code: "4-9-1",
      },
      "EL NANCITO": {
        code: "4-9-2",
      },
      "EL PORVENIR": {
        code: "4-9-3",
      },
      "EL PUERTO": {
        code: "4-9-4",
      },
      "SANTA LUCIA": {
        code: "4-9-5",
      },
    },
    RENACIMIENTO: {
      "RIO SERENO (CABECERA)": {
        code: "4-10-1",
      },
      BREÑON: {
        code: "4-10-2",
      },
      "CAÑAS GORDAS": {
        code: "4-10-3",
      },
      "MONTE LIRIO": {
        code: "4-10-4",
      },
      "PLAZA CAISAN": {
        code: "4-10-5",
      },
      "SANTA CRUZ": {
        code: "4-10-6",
      },
      DOMINICAL: {
        code: "4-10-7",
      },
      "SANTA CLARA": {
        code: "4-10-8",
      },
    },
    "SAN FELIX": {
      "LAS LAJAS (CABECERA)": {
        code: "4-11-1",
      },
      JUAY: {
        code: "4-11-2",
      },
      "LAJAS ADENTRO": {
        code: "4-11-3",
      },
      "SAN FELIX": {
        code: "4-11-4",
      },
      "SANTA CRUZ": {
        code: "4-11-5",
      },
    },
    "SAN LORENZO": {
      "HORCONCITOS (CABECERA)": {
        code: "4-12-1",
      },
      "BOCA CHICA": {
        code: "4-12-2",
      },
      "BOCA DEL MONTE": {
        code: "4-12-3",
      },
      "SAN JUAN": {
        code: "4-12-4",
      },
      "SAN LORENZO": {
        code: "4-12-5",
      },
    },
    TOLE: {
      "TOLE (CABECERA)": {
        code: "4-13-1",
      },
      "BELLA VISTA": {
        code: "4-13-2",
      },
      "CERRO VIEJO": {
        code: "4-13-3",
      },
      "EL CRISTO": {
        code: "4-13-4",
      },
      "JUSTO FIDEL PALACIOS": {
        code: "4-13-5",
      },
      "LAJAS DE TOLE": {
        code: "4-13-6",
      },
      "POTRERO DE CAÑA": {
        code: "4-13-7",
      },
      "QUEBRADA DE PIEDRA": {
        code: "4-13-8",
      },
      VELADERO: {
        code: "4-13-9",
      },
    },
  },
  DARIEN: {
    CHEPIGANA: {
      "LA PALMA (CABECERA)": {
        code: "5-1-1",
      },
      CAMOGANTI: {
        code: "5-1-2",
      },
      CHEPIGANA: {
        code: "5-1-3",
      },
      GARACHINE: {
        code: "5-1-4",
      },
      JAQUE: {
        code: "5-1-5",
      },
      "PUERTO PIÑA": {
        code: "5-1-6",
      },
      "RIO CONGO": {
        code: "5-1-7",
      },
      "RIO IGLESIAS": {
        code: "5-1-8",
      },
      SAMBU: {
        code: "5-1-9",
      },
      SETEGANTI: {
        code: "5-1-10",
      },
      TAIMATI: {
        code: "5-1-11",
      },
      TUCUTI: {
        code: "5-1-12",
      },
      "AGUA FRIA": {
        code: "5-1-13",
      },
      CUCUNATI: {
        code: "5-1-14",
      },
      "RIO CONGO ARRIBA": {
        code: "5-1-15",
      },
      "SANTA FE": {
        code: "5-1-16",
      },
    },
    PINOGANA: {
      "EL REAL DE SANTA MARIA (CABECE": {
        code: "5-2-1",
      },
      "BOCA DE CUPE": {
        code: "5-2-2",
      },
      PAYA: {
        code: "5-2-3",
      },
      PINOGANA: {
        code: "5-2-4",
      },
      PUCURO: {
        code: "5-2-5",
      },
      YAPE: {
        code: "5-2-6",
      },
      YAVIZA: {
        code: "5-2-7",
      },
      METETI: {
        code: "5-2-8",
      },
      "COMARCA KUNA DE WARGANDI": {
        code: "5-2-9",
      },
    },
  },
  HERRERA: {
    CHITRE: {
      "CHITRE (CABECERA)": {
        code: "6-1-1",
      },
      "LA ARENA": {
        code: "6-1-2",
      },
      MONAGRILLO: {
        code: "6-1-3",
      },
      "LLANO BONITO": {
        code: "6-1-4",
      },
      "SAN JUAN BAUTISTA": {
        code: "6-1-5",
      },
    },
    "LAS MINAS": {
      "LAS MINAS (CABECERA)": {
        code: "6-2-1",
      },
      CHEPO: {
        code: "6-2-2",
      },
      CHUMICAL: {
        code: "6-2-3",
      },
      "EL TORO": {
        code: "6-2-4",
      },
      LEONES: {
        code: "6-2-5",
      },
      "QUEBRADA DEL ROSARIO": {
        code: "6-2-6",
      },
      "QUEBRADA EL CIPRIAN": {
        code: "6-2-7",
      },
    },
    "LOS POZOS": {
      "LOS POZOS (CABECERA)": {
        code: "6-3-1",
      },
      CAPURI: {
        code: "6-3-2",
      },
      "EL CALABACITO": {
        code: "6-3-3",
      },
      "EL CEDRO": {
        code: "6-3-4",
      },
      "LA ARENA": {
        code: "6-3-5",
      },
      "LA PITALOSA": {
        code: "6-3-6",
      },
      "LOS CERRITOS": {
        code: "6-3-7",
      },
      "LOS CERROS DE PAJA": {
        code: "6-3-8",
      },
      "LAS LLANAS": {
        code: "6-3-9",
      },
    },
    OCU: {
      "OCU (CABECERA)": {
        code: "6-4-1",
      },
      "CERRO LARGO": {
        code: "6-4-2",
      },
      "LOS LLANOS": {
        code: "6-4-3",
      },
      "LLANO GRANDE": {
        code: "6-4-4",
      },
      "PEÑAS CHATAS": {
        code: "6-4-5",
      },
      "EL TIJERA": {
        code: "6-4-6",
      },
      MENCHACA: {
        code: "6-4-7",
      },
    },
    PARITA: {
      "PARITA (CABECERA)": {
        code: "6-5-1",
      },
      CABUYA: {
        code: "6-5-2",
      },
      "LOS CASTILLOS": {
        code: "6-5-3",
      },
      "LLANO DE LA CRUZ": {
        code: "6-5-4",
      },
      PARIS: {
        code: "6-5-5",
      },
      PORTOBELILLO: {
        code: "6-5-6",
      },
      POTUGA: {
        code: "6-5-7",
      },
    },
    PESE: {
      "PESE (CABECERA)": {
        code: "6-6-1",
      },
      "LAS CABRAS": {
        code: "6-6-2",
      },
      "EL PAJARO": {
        code: "6-6-3",
      },
      "EL BARRERO": {
        code: "6-6-4",
      },
      "EL PEDREGOSO": {
        code: "6-6-5",
      },
      "EL CIRUELO": {
        code: "6-6-6",
      },
      SABANAGRANDE: {
        code: "6-6-7",
      },
      "RINCON HONDO": {
        code: "6-6-8",
      },
    },
    "SANTA MARIA": {
      "SANTA MARIA (CABECERA)": {
        code: "6-7-1",
      },
      CHUPAMPA: {
        code: "6-7-2",
      },
      "EL RINCON": {
        code: "6-7-3",
      },
      "EL LIMON": {
        code: "6-7-4",
      },
      "LOS CANELOS": {
        code: "6-7-5",
      },
    },
  },
  "LOS SANTOS": {
    GUARARE: {
      "GUARARE (CABECERA)": {
        code: "7-1-1",
      },
      "EL ESPINAL": {
        code: "7-1-2",
      },
      "EL MACANO": {
        code: "7-1-3",
      },
      "GUARARE ARRIBA": {
        code: "7-1-4",
      },
      "LA ENEA": {
        code: "7-1-5",
      },
      "LA PASERA": {
        code: "7-1-6",
      },
      "LAS TRANCAS": {
        code: "7-1-7",
      },
      "LLANO ABAJO": {
        code: "7-1-8",
      },
      "EL HATO": {
        code: "7-1-9",
      },
      PERALES: {
        code: "7-1-10",
      },
    },
    "LAS TABLAS": {
      "LAS TABLAS (CABECERA)": {
        code: "7-2-1",
      },
      "BAJO CORRAL": {
        code: "7-2-2",
      },
      BAYANO: {
        code: "7-2-3",
      },
      "EL CARATE": {
        code: "7-2-4",
      },
      "EL COCAL": {
        code: "7-2-5",
      },
      "EL MANANTIAL": {
        code: "7-2-6",
      },
      "EL MUÑOZ": {
        code: "7-2-7",
      },
      "EL PEDREGOSO": {
        code: "7-2-8",
      },
      "LA LAJA": {
        code: "7-2-9",
      },
      "LA MIEL": {
        code: "7-2-10",
      },
      "LA PALMA": {
        code: "7-2-11",
      },
      "LA TIZA": {
        code: "7-2-12",
      },
      "LAS PALMITAS": {
        code: "7-2-13",
      },
      "LAS TABLAS ABAJO": {
        code: "7-2-14",
      },
      NUARIO: {
        code: "7-2-15",
      },
      PALMIRA: {
        code: "7-2-16",
      },
      "PEÑA BLANCA": {
        code: "7-2-17",
      },
      "RIO HONDO": {
        code: "7-2-18",
      },
      "SAN JOSE": {
        code: "7-2-19",
      },
      "SAN MIGUEL": {
        code: "7-2-20",
      },
      "SANTO DOMINGO": {
        code: "7-2-21",
      },
      SESTEADERO: {
        code: "7-2-22",
      },
      "VALLE RICO": {
        code: "7-2-23",
      },
      VALLERIQUITO: {
        code: "7-2-24",
      },
    },
    "LOS SANTOS": {
      "LA VILLA DE LOS SANTOS (CABECE": {
        code: "7-3-1",
      },
      "EL GUASIMO": {
        code: "7-3-2",
      },
      "LA COLORADA": {
        code: "7-3-3",
      },
      "LA ESPIGADILLA": {
        code: "7-3-4",
      },
      "LAS CRUCES": {
        code: "7-3-5",
      },
      "LAS GUABAS": {
        code: "7-3-6",
      },
      "LOS ANGELES": {
        code: "7-3-7",
      },
      "LOS OLIVOS": {
        code: "7-3-8",
      },
      "LLANO LARGO": {
        code: "7-3-9",
      },
      SABANAGRANDE: {
        code: "7-3-10",
      },
      "SANTA ANA": {
        code: "7-3-11",
      },
      "TRES QUEBRADAS": {
        code: "7-3-12",
      },
      "AGUA BUENA": {
        code: "7-3-13",
      },
      "VILLA LOURDES": {
        code: "7-3-14",
      },
    },
    MACARACAS: {
      "MACARACAS (CABECERA)": {
        code: "7-4-1",
      },
      "BAHIA HONDA": {
        code: "7-4-2",
      },
      "BAJOS DE GUERA": {
        code: "7-4-3",
      },
      COROZAL: {
        code: "7-4-4",
      },
      CHUPA: {
        code: "7-4-5",
      },
      "EL CEDRO": {
        code: "7-4-6",
      },
      "ESPINO AMARILLO": {
        code: "7-4-7",
      },
      "LA MESA": {
        code: "7-4-8",
      },
      "LAS PALMAS": {
        code: "7-4-9",
      },
      "LLANO DE PIEDRA": {
        code: "7-4-10",
      },
      MOGOLLON: {
        code: "7-4-11",
      },
    },
    PEDASI: {
      "PEDASI (CABECERA)": {
        code: "7-5-1",
      },
      "LOS ASIENTOS": {
        code: "7-5-2",
      },
      MARIABE: {
        code: "7-5-3",
      },
      PURIO: {
        code: "7-5-4",
      },
      "ORIA ARRIBA": {
        code: "7-5-5",
      },
    },
    POCRI: {
      POCRI: {
        code: "7-6-1",
      },
      "EL CAÑAFISTULO": {
        code: "7-6-2",
      },
      LAJAMINA: {
        code: "7-6-3",
      },
      PARAISO: {
        code: "7-6-4",
      },
      PARITILLA: {
        code: "7-6-5",
      },
    },
    TONOSI: {
      "TONOSI (CABECERA)": {
        code: "7-7-1",
      },
      "ALTOS DE GUERA": {
        code: "7-7-2",
      },
      CAÑAS: {
        code: "7-7-3",
      },
      "EL BEBEDERO": {
        code: "7-7-4",
      },
      "EL CACAO": {
        code: "7-7-5",
      },
      "EL CORTEZO": {
        code: "7-7-6",
      },
      FLORES: {
        code: "7-7-7",
      },
      GUANICO: {
        code: "7-7-8",
      },
      "LA TRONOSA": {
        code: "7-7-9",
      },
      CAMBUTAL: {
        code: "7-7-10",
      },
      "ISLA DE CAQAS": {
        code: "7-7-11",
      },
    },
  },
  PANAMA: {
    BALBOA: {
      "SAN MIGUEL (CABECERA)": {
        code: "8-2-1",
      },
      "LA ENSENADA": {
        code: "8-2-2",
      },
      "LA ESMERALDA": {
        code: "8-2-3",
      },
      "LA GUINEA": {
        code: "8-2-4",
      },
      "PEDRO GONZALEZ": {
        code: "8-2-5",
      },
      SABOGA: {
        code: "8-2-6",
      },
    },
    CHEPO: {
      "CHEPO (CABECERA)": {
        code: "8-5-1",
      },
      CAÑITA: {
        code: "8-5-2",
      },
      CHEPILLO: {
        code: "8-5-3",
      },
      "EL LLANO": {
        code: "8-5-4",
      },
      "LAS MARGARITAS": {
        code: "8-5-5",
      },
      "SANTA CRUZ DE CHININA": {
        code: "8-5-6",
      },
      "COMARCA KUNA DE MADUNGANDI": {
        code: "8-5-7",
      },
      TORTI: {
        code: "8-5-8",
      },
    },
    CHIMAN: {
      "CHIMAN (CABECERA)": {
        code: "8-6-1",
      },
      BRUJAS: {
        code: "8-6-2",
      },
      "GONZALO VASQUEZ": {
        code: "8-6-3",
      },
      PASIGA: {
        code: "8-6-4",
      },
      "UNION SANTEÑA": {
        code: "8-6-5",
      },
    },
    PANAMA: {
      "SAN FELIPE": {
        code: "8-8-1",
      },
      "EL CHORRILLO": {
        code: "8-8-2",
      },
      "SANTA ANA": {
        code: "8-8-3",
      },
      "LA EXPOSICION O CALIDONIA": {
        code: "8-8-4",
      },
      CURUNDU: {
        code: "8-8-5",
      },
      BETANIA: {
        code: "8-8-6",
      },
      "BELLA VISTA": {
        code: "8-8-7",
      },
      "PUEBLO NUEVO": {
        code: "8-8-8",
      },
      "SAN FRANCISCO": {
        code: "8-8-9",
      },
      "PARQUE LEFEVRE": {
        code: "8-8-10",
      },
      "RIO ABAJO": {
        code: "8-8-11",
      },
      "JUAN DIAZ": {
        code: "8-8-12",
      },
      PEDREGAL: {
        code: "8-8-13",
      },
      ANCON: {
        code: "8-8-14",
      },
      CHILIBRE: {
        code: "8-8-15",
      },
      "LAS CUMBRES": {
        code: "8-8-16",
      },
      PACORA: {
        code: "8-8-17",
      },
      "SAN MARTIN": {
        code: "8-8-18",
      },
      TOCUMEN: {
        code: "8-8-19",
      },
      "LAS MAQANITAS": {
        code: "8-8-20",
      },
      "24 DE DICIEMBRE": {
        code: "8-8-21",
      },
      "ERNESTO CORDOBA CAMPOS": {
        code: "8-8-22",
      },
      "ALCALDE DIAZ": {
        code: "8-8-23",
      },
      CAIMITILLO: {
        code: "8-8-24",
      },
      "DON BOSCO": {
        code: "8-8-25",
      },
      "LAS GARZAS": {
        code: "8-8-26",
      },
    },
    "SAN MIGUELITO": {
      "AMELIA DENIS DE ICAZA": {
        code: "8-10-1",
      },
      "BELISARIO PORRAS": {
        code: "8-10-2",
      },
      "JOSE DOMINGO ESPINAR": {
        code: "8-10-3",
      },
      "MATEO ITURRALDE": {
        code: "8-10-4",
      },
      "VICTORIANO LORENZO": {
        code: "8-10-5",
      },
      "ARNULFO ARIAS": {
        code: "8-10-6",
      },
      "BELISARIO FRIAS": {
        code: "8-10-7",
      },
      "OMAR TORRIJOS": {
        code: "8-10-8",
      },
      "RUFINA ALFARO": {
        code: "8-10-9",
      },
    },
    TABOGA: {
      "TABOGA (CABECERA)": {
        code: "8-11-1",
      },
      "OTOQUE ORIENTE": {
        code: "8-11-2",
      },
      "OTOQUE OCCIDENTE": {
        code: "8-11-3",
      },
    },
  },
  VERAGUAS: {
    ATALAYA: {
      "ATALAYA (CABECERA)": {
        code: "9-1-1",
      },
      "EL BARRITO": {
        code: "9-1-2",
      },
      "LA MONTAÑUELA": {
        code: "9-1-3",
      },
      "LA CARRILLO": {
        code: "9-1-4",
      },
      "SAN ANTONIO": {
        code: "9-1-5",
      },
    },
    CALOBRE: {
      "CALOBRE (CABECERA)": {
        code: "9-2-1",
      },
      BARNIZAL: {
        code: "9-2-2",
      },
      CHITRA: {
        code: "9-2-3",
      },
      "EL COCLA": {
        code: "9-2-4",
      },
      "EL POTRERO": {
        code: "9-2-5",
      },
      "LA LAGUNA": {
        code: "9-2-6",
      },
      "LA RAYA DE CALOBRE": {
        code: "9-2-7",
      },
      "LA TETILLA": {
        code: "9-2-8",
      },
      "LA YEGUADA": {
        code: "9-2-9",
      },
      "LAS GUIAS": {
        code: "9-2-10",
      },
      MONJARAS: {
        code: "9-2-11",
      },
      "SAN JOSE": {
        code: "9-2-12",
      },
    },
    CAÑAZAS: {
      "CAÑAZAS (CABECERA)": {
        code: "9-3-1",
      },
      "CERRO PLATA": {
        code: "9-3-2",
      },
      "EL PICADOR": {
        code: "9-3-3",
      },
      "LOS VALLES": {
        code: "9-3-4",
      },
      "SAN JOSE": {
        code: "9-3-5",
      },
      "SAN MARCELO": {
        code: "9-3-6",
      },
      "EL AROMILLO": {
        code: "9-3-7",
      },
    },
    "LA MEZA": {
      "LA MESA (CABECERA)": {
        code: "9-4-1",
      },
      BISVALLES: {
        code: "9-4-2",
      },
      BORO: {
        code: "9-4-3",
      },
      "LLANO GRANDE": {
        code: "9-4-4",
      },
      "SAN BARTOLO": {
        code: "9-4-5",
      },
      "LOS MILAGROS": {
        code: "9-4-6",
      },
    },
    "LAS PALMAS": {
      "LAS PALMAS (CABECERA)": {
        code: "9-5-1",
      },
      "CERRO DE CASA": {
        code: "9-5-2",
      },
      COROZAL: {
        code: "9-5-3",
      },
      "EL MARIA": {
        code: "9-5-4",
      },
      "EL PRADO": {
        code: "9-5-5",
      },
      "EL RINCON": {
        code: "9-5-6",
      },
      LOLA: {
        code: "9-5-7",
      },
      PIXVAE: {
        code: "9-5-8",
      },
      "PUERTO VIDAL": {
        code: "9-5-9",
      },
      "SAN MARTIN DE PORRES": {
        code: "9-5-10",
      },
      VIGUI: {
        code: "9-5-11",
      },
      ZAPOTILLO: {
        code: "9-5-12",
      },
    },
    MONTIJO: {
      "MONTIJO (CABECERA)": {
        code: "9-6-1",
      },
      ARENAS: {
        code: "9-6-2",
      },
      GOBERNADORA: {
        code: "9-6-3",
      },
      "LA GARCEANA": {
        code: "9-6-4",
      },
      LEONES: {
        code: "9-6-5",
      },
      CEBACO: {
        code: "9-6-6",
      },
      "COSTA HERMOSA": {
        code: "9-6-7",
      },
      "UNION DEL NORTE": {
        code: "9-6-8",
      },
      TEBARIO: {
        code: "9-6-9",
      },
      "EL PILON": {
        code: "9-6-10",
      },
    },
    "RIO DE JESUS": {
      "RIO DE JESUS (CABECERA)": {
        code: "9-7-1",
      },
      "LAS HUACAS": {
        code: "9-7-2",
      },
      "LOS CASTILLOS": {
        code: "9-7-3",
      },
      UTIRA: {
        code: "9-7-4",
      },
      "CATORCE DE NOVIEMBRE": {
        code: "9-7-5",
      },
    },
    "SAN FRANCISCO": {
      "SAN FRANCISCO (CABECERA)": {
        code: "9-8-1",
      },
      "CORRAL FALSO": {
        code: "9-8-2",
      },
      "LOS HATILLOS": {
        code: "9-8-3",
      },
      REMANCE: {
        code: "9-8-4",
      },
      "SAN JUAN": {
        code: "9-8-5",
      },
      "SAN JOSE": {
        code: "9-8-6",
      },
    },
    "SANTA FE": {
      "SANTA FE (CABECERA)": {
        code: "9-9-1",
      },
      CALOVEBORA: {
        code: "9-9-2",
      },
      "EL ALTO": {
        code: "9-9-3",
      },
      "EL CUAY": {
        code: "9-9-4",
      },
      "EL PANTANO": {
        code: "9-9-5",
      },
      "GATU O GATUCITO": {
        code: "9-9-6",
      },
      "RIO LUIS": {
        code: "9-9-7",
      },
    },
    SANTIAGO: {
      "SANTIAGO (CABECERA)": {
        code: "9-10-1",
      },
      "LA COLORADA": {
        code: "9-10-2",
      },
      "LA PEÑA": {
        code: "9-10-3",
      },
      "LA RAYA DE SANTA MARIA": {
        code: "9-10-4",
      },
      PONUGA: {
        code: "9-10-5",
      },
      "SAN PEDRO DEL ESPINO": {
        code: "9-10-6",
      },
      "CANTO DEL LLANO": {
        code: "9-10-7",
      },
      "LOS ALGARROBOS": {
        code: "9-10-8",
      },
      "CARLOS SANTANA AVILA": {
        code: "9-10-9",
      },
      "EDWIN FABREGA": {
        code: "9-10-10",
      },
      "SAN MARTIN DE PORRES": {
        code: "9-10-11",
      },
      URRACA: {
        code: "9-10-12",
      },
    },
    SONA: {
      "SONA (CABECERA)": {
        code: "9-11-1",
      },
      "BAHIA HONDA": {
        code: "9-11-2",
      },
      CALIDONIA: {
        code: "9-11-3",
      },
      CATIVE: {
        code: "9-11-4",
      },
      "EL MARAÑON": {
        code: "9-11-5",
      },
      GUARUMAL: {
        code: "9-11-6",
      },
      "LA SOLEDAD": {
        code: "9-11-7",
      },
      "QUEBRADA DE ORO": {
        code: "9-11-8",
      },
      "RIO GRANDE": {
        code: "9-11-9",
      },
      "RODEO VIEJO": {
        code: "9-11-10",
      },
    },
    MARIATO: {
      "LLANO DE CATIVAL O MARIATO (CABECERA)": {
        code: "9-12-1",
      },
      ARENAS: {
        code: "9-12-2",
      },
      "EL CACAO": {
        code: "9-12-3",
      },
      QUEBRO: {
        code: "9-12-4",
      },
      TEBARIO: {
        code: "9-12-5",
      },
    },
  },
  "COMARCA KUNA YALA": {
    "COMARCA KUNA YALA": {
      "NARGANA (CABECERA)": {
        code: "10-1-1",
      },
      AILIGANDI: {
        code: "10-1-2",
      },
      "PUERTO OBALDIA": {
        code: "10-1-3",
      },
      TUBUALA: {
        code: "10-1-4",
      },
    },
  },
  "COMARCA EMBERA-WOUNAAN": {
    CEMACO: {
      "CIRILO GUAINORA (CABECERA)": {
        code: "11-1-1",
      },
      "LAJAS BLANCAS": {
        code: "11-1-2",
      },
      "MANUEL ORTEGA": {
        code: "11-1-3",
      },
    },
    SAMBU: {
      "RIO SABALO": {
        code: "11-2-1",
      },
      JINGURUDO: {
        code: "11-2-2",
      },
    },
  },
  "COMARCA NGÄBE-BUGLÉ": {
    BESIKO: {
      "SOLOY (CABECERA)": {
        code: "12-1-1",
      },
      "BOCA DE BALSA": {
        code: "12-1-2",
      },
      "CAMARON ARRIBA": {
        code: "12-1-3",
      },
      "CERRO BANCO": {
        code: "12-1-4",
      },
      "CERRO DE PATENA": {
        code: "12-1-5",
      },
      "EMPLANADA DE CHORCHA": {
        code: "12-1-6",
      },
      NAMNONI: {
        code: "12-1-7",
      },
      NIBA: {
        code: "12-1-8",
      },
    },
    MIRONO: {
      "HATO PILON (CABECERA)": {
        code: "12-2-1",
      },
      CASCABEL: {
        code: "12-2-2",
      },
      "HATO COROTU": {
        code: "12-2-3",
      },
      "HATO CULANTRO": {
        code: "12-2-4",
      },
      "HATO JOBO": {
        code: "12-2-5",
      },
      "HATO JULI": {
        code: "12-2-6",
      },
      "QUEBRADA DE LORO": {
        code: "12-2-7",
      },
      "SALTO DUPI": {
        code: "12-2-8",
      },
    },
    MUNA: {
      "CHICHICA (CABECERA)": {
        code: "12-3-1",
      },
      "ALTO CABALLERO": {
        code: "12-3-2",
      },
      BAKAMA: {
        code: "12-3-3",
      },
      "CERRO CAÑA": {
        code: "12-3-4",
      },
      "CERRO PUERCO": {
        code: "12-3-5",
      },
      KRUA: {
        code: "12-3-6",
      },
      MARACA: {
        code: "12-3-7",
      },
      NIBRA: {
        code: "12-3-8",
      },
      "PEÑA BLANCA": {
        code: "12-3-9",
      },
      ROKA: {
        code: "12-3-10",
      },
      "SITIO PRADO": {
        code: "12-3-11",
      },
      UMANI: {
        code: "12-3-12",
      },
    },
    "NOLE DUIMA": {
      "CERRO IGLESIAS (CABECERA)": {
        code: "12-4-1",
      },
      "HATO CHAMI": {
        code: "12-4-2",
      },
      JADEBERI: {
        code: "12-4-3",
      },
      LAJERO: {
        code: "12-4-4",
      },
      SUSAMA: {
        code: "12-4-5",
      },
    },
    NURUM: {
      "BUENOS AIRES (CABECERA)": {
        code: "12-5-1",
      },
      "AGUA DE SALUD": {
        code: "12-5-2",
      },
      "ALTO DE JESUS": {
        code: "12-5-3",
      },
      "CERRO PELADO": {
        code: "12-5-4",
      },
      "EL BALE": {
        code: "12-5-5",
      },
      "EL PAREDON": {
        code: "12-5-6",
      },
      "EL PIRO": {
        code: "12-5-7",
      },
      GUAYABITO: {
        code: "12-5-8",
      },
      GUIBALE: {
        code: "12-5-9",
      },
    },
    KANKINTU: {
      "BISIRA (CABECERA)": {
        code: "12-6-1",
      },
      BURI: {
        code: "12-6-2",
      },
      GUARIVIARA: {
        code: "12-6-3",
      },
      GUORONI: {
        code: "12-6-4",
      },
      KANKINTU: {
        code: "12-6-5",
      },
      MUNUNI: {
        code: "12-6-6",
      },
      "PIEDRA ROJA": {
        code: "12-6-7",
      },
      TUWAI: {
        code: "12-6-8",
      },
      "MAN CREEK": {
        code: "12-6-9",
      },
    },
    KUSAPIN: {
      "KUSAPIN (CABECERA)": {
        code: "12-7-1",
      },
      "BAHIA AZUL": {
        code: "12-7-2",
      },
      "CALOVEBORA O SANTA CATALINA": {
        code: "12-7-3",
      },
      "LOMA YUCA": {
        code: "12-7-4",
      },
      "RIO CHIRIQUI": {
        code: "12-7-5",
      },
      TOBOBE: {
        code: "12-7-6",
      },
      "VALLE BONITO": {
        code: "12-7-7",
      },
    },
  },
  "PANAMA OESTE": {
    ARRAIJAN: {
      "ARRAIJAN (CABECERA)": {
        code: "13-1-1",
      },
      BURUNGA: {
        code: "13-1-2",
      },
      "CERRO SILVESTRE": {
        code: "13-1-3",
      },
      "JUAN DEMOSTENES AROSEMENA": {
        code: "13-1-4",
      },
      "NUEVO EMPERADOR": {
        code: "13-1-5",
      },
      "SANTA CLARA": {
        code: "13-1-6",
      },
      VERACRUZ: {
        code: "13-1-7",
      },
      "VISTA ALEGRE": {
        code: "13-1-8",
      },
    },
    CAPIRA: {
      "CAPIRA (CABECERA)": {
        code: "13-2-1",
      },
      CAIMITO: {
        code: "13-2-2",
      },
      CAMPANA: {
        code: "13-2-3",
      },
      CERMEÑO: {
        code: "13-2-4",
      },
      "CIRI DE LOS SOTOS": {
        code: "13-2-5",
      },
      "CIRI GRANDE": {
        code: "13-2-6",
      },
      "EL CACAO": {
        code: "13-2-7",
      },
      "LA TRINIDAD": {
        code: "13-2-8",
      },
      "LAS OLLAS ARRIBA": {
        code: "13-2-9",
      },
      LIDICE: {
        code: "13-2-10",
      },
      "VILLA CARMEN": {
        code: "13-2-11",
      },
      "VILLA ROSARIO": {
        code: "13-2-12",
      },
      "SANTA ROSA": {
        code: "13-2-13",
      },
    },
    CHAME: {
      "CHAME (CABECERA)": {
        code: "13-3-1",
      },
      BEJUCO: {
        code: "13-3-2",
      },
      "BUENOS AIRES": {
        code: "13-3-3",
      },
      CABUYA: {
        code: "13-3-4",
      },
      CHICA: {
        code: "13-3-5",
      },
      "EL LIBANO": {
        code: "13-3-6",
      },
      "LAS LAJAS": {
        code: "13-3-7",
      },
      "NUEVA GORGONA": {
        code: "13-3-8",
      },
      "PUNTA CHAME": {
        code: "13-3-9",
      },
      SAJALICES: {
        code: "13-3-10",
      },
      SORA: {
        code: "13-3-11",
      },
    },
    "LA CHORRERA": {
      "BARRIO BALBOA": {
        code: "13-4-1",
      },
      "BARRIO COLON": {
        code: "13-4-2",
      },
      AMADOR: {
        code: "13-4-3",
      },
      AROSEMENA: {
        code: "13-4-4",
      },
      "EL ARADO": {
        code: "13-4-5",
      },
      "EL COCO": {
        code: "13-4-6",
      },
      FEUILLET: {
        code: "13-4-7",
      },
      GUADALUPE: {
        code: "13-4-8",
      },
      HERRERA: {
        code: "13-4-9",
      },
      HURTADO: {
        code: "13-4-10",
      },
      ITURRALDE: {
        code: "13-4-11",
      },
      "LA REPRESA": {
        code: "13-4-12",
      },
      "LOS DIAZ": {
        code: "13-4-13",
      },
      MENDOZA: {
        code: "13-4-14",
      },
      OBALDIA: {
        code: "13-4-15",
      },
      "PLAYA LEONA": {
        code: "13-4-16",
      },
      "PUERTO CAIMITO": {
        code: "13-4-17",
      },
      "SANTA RITA": {
        code: "13-4-18",
      },
    },
    "SAN CARLOS": {
      "SAN CARLOS (CABECERA)": {
        code: "13-5-1",
      },
      "EL ESPINO": {
        code: "13-5-2",
      },
      "EL HIGO": {
        code: "13-5-3",
      },
      GUAYABITO: {
        code: "13-5-4",
      },
      "LA ERMITA": {
        code: "13-5-5",
      },
      "LA LAGUNA": {
        code: "13-5-6",
      },
      "LAS UVAS": {
        code: "13-5-7",
      },
      "LOS LLANITOS": {
        code: "13-5-8",
      },
      "SAN JOSE": {
        code: "13-5-9",
      },
    },
  },
};
