import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { setAmountToPay } from "../../../../data-store/actions/vehicle-actions";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function BillingFormSlim(props) {
  const { callback, billingData } = props;
  const dispatch = useDispatch();

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState(false);
  const [showForm, setShowForm] = useState(false);

  function onSubmit(data) {
    // setCardNumber(data.cardNumber);
    // setCardOwner(data.cardOwner);
    seterrorMessage(false);
    // console.log("data", data);
    // console.log("data", cardNumber);
    // console.log("data", sending);
    setShowForm(false);
    callback(data);
  }

  const [formData, setFormData] = useState({
    businessName: "",
    email: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    callback(formData);
    setShowForm(false);
  };

  if (!showForm) {
    return (
      <Grid
        container
        direction="row"
        style={{
          height: "auto",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          alignItems={"left"}
          style={{ margin: "40px" }}
        >
          <Button
            onClick={() => setShowForm(true)}
            color="primary"
            variant="contained"
            style={{ padding: 30, width: 400, fontSize: 15 }}
          >
            <Grid container direction="row">
              <Grid item xs={12} sm={12}>
                Pedir Factura Fiscal sin datos
              </Grid>
              <Grid item xs={12} sm={12}></Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        alignItems={"center"}
        style={{ margin: "40px" }}
      >
        <Button
          onClick={() => setShowForm(false)}
          color="primary"
          variant="contained"
          style={{ padding: 30, width: 400, fontSize: 20 }}
        >
          Ocultar formulario de facturación
        </Button>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Razón Social"
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Correo Electrónico"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Guardar Datos
            </Button>
          </Grid>
        </Grid>
      </form>{" "}
    </Grid>
  );
}
