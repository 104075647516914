import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";
import QrScanner from "../../../QrScanner/QrScanner";
import VehicleDetail from "./VehicleDetail";
import { getVehicleRealTimeChanges } from "../../../../data-store/actions/vehicle-actions";
import { Button, Paper } from "@material-ui/core";
import { getCatalogueFunc } from "../../../../data-store/actions/auth-actions";
import Loading from "../../../LayoutContainers/Loading";
import PaymentContainer from "../Payment/PaymentContainer";
import BillingConfirmation from "../Payment/BillingConfirmation";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function RequestVehicle(props) {
  const {
    logo,
    title,
    text,
    image,
    bgColor,
    textColor,
    imageHeight,
  } = props.componentConfig;
  const dispatch = useDispatch();
  const [scanned, setScanned] = useState(true);
  const [openProps, setopenProps] = useState(false);

  const catalogue = useSelector((state) => state.auth.catalogue);
  const languageObject = useSelector((state) => state.auth.languageObject);
  const loading = useSelector((state) => state.auth.loading);
  const texts = languageObject.view;

  const currentVehicle = useSelector((state) => state.vehicle.currentVehicle);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  useEffect(() => {
    if (catalogue === undefined) {
      dispatch(getCatalogueFunc());
    }
    if (query.get("business") && query.get("location") && query.get("card")) {
      console.log();
      dispatch(
        getVehicleRealTimeChanges(
          query.get("business"),
          query.get("location"),
          query.get("card")
        )
      );
    }
  }, []);

  function callback(data) {
    callBackOpen(false);
    //const data = "smartvalet.app/vp/card=AAA&business=bbb&location=ccc";
    console.log(data);
    if (data.indexOf("smartvalet.app" >= 0)) {
      const businessId = data.substr(
        data.indexOf("business=") + 9,
        data.indexOf("&location=") - (data.indexOf("business=") + 9)
      );
      const locationId = data.substr(
        data.indexOf("location=") + 9,
        data.indexOf("&card=") - (data.indexOf("location=") + 9)
      );
      const card = data.substr(data.indexOf("card=") + 5, 4);
      console.log(businessId);
      console.log(locationId);
      console.log(card);
      if (scanned) {
        dispatch(getVehicleRealTimeChanges(businessId, locationId, card));
        setScanned(false);
      }
      setTimeout(() => setScanned(true), 1000);
    }
  }
  function callBackOpen(open) {
    setopenProps(open);
  }

  // const data =
  //   "smartvalet.app/vp/business=idbusiness_2&location=location_2&card=AAA";
  // console.log(data);
  // if (data.indexOf("smartvalet.app" >= 0)) {
  //   const businessId = data.substr(
  //     data.indexOf("business=") + 9,
  //     data.indexOf("&location=") - data.indexOf("business=") - 9
  //   );
  //   const locationId = data.substr(
  //     data.indexOf("location=") + 9,
  //     data.indexOf("&card=") - data.indexOf("location=") - 9
  //   );
  //   const card = data.substr(data.indexOf("card=") + 5, 4);
  //   console.log(businessId);
  //   console.log(locationId);
  //   console.log(card);

  //   //dispatch(getVehiclesRealTimeChanges(businessId, locationId, card));
  // }
  console.log("query.get", query.get("card"));

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 3000,
            zIndex: "1000",
          }}
        >
          <Loading />
        </div>
      )}{" "}
      <Grid
        container
        direction="row"
        style={{
          backgroundColor: bgColor,
          width: "100%",
          padding: "0px",
        }}
      >
        {query.get("business") === "zmbNAeeZhUQdw8KN8Eev" &&
          (query.get("location") === "locationId_1" ||
            query.get("location") === "locationId_17") && (
            <Grid item xs={12} sm={12}>
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/softicade.appspot.com/o/VPP%2FMC_SolicitudSV.jpg?alt=media&token=aee79e70-b9f4-41b0-9e33-9f0f49d1fdb9"
                }
                alt={"MasterCard"}
                width="100%"
              />
            </Grid>
          )}

        <Grid item xs={12} sm={12} style={{ paddingLeft: "20px" }}>
          <Typography
            variant="h3"
            style={{ color: textColor, textAlign: "center", marginTop: 30 }}
          >
            {title}
          </Typography>
          {currentVehicle === undefined && query.get("card") === null && (
            <Typography
              variant="h5"
              style={{ color: textColor, textAlign: "center", marginTop: 30 }}
            >
              {text}
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          style={{
            paddingTop: 50,
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <QrScanner callback={callback} visible={openProps} />

          {currentVehicle === undefined && query.get("card") === null && (
            <Button
              variant="contained"
              color="primary"
              style={{ fontSize: currentVehicle === undefined ? 40 : 15 }}
              onClick={() => setopenProps(!openProps)}
            >
              {openProps ? texts.closeScann : texts.scann}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={12} style={{ paddingLeft: "20px" }}>
          <VehicleDetail
            textColor={textColor}
            bgColor={bgColor}
            card={query.get("card")}
            businessId={query.get("business")}
            locationId={query.get("location")}
          />
        </Grid>

        <Grid item xs={12} sm={12} style={{ paddingLeft: "20px" }}>
          {currentVehicle &&
            (query.get("business") === "idbusiness_2" ||
              query.get("business") === "kmq9dvl0EYjyhHPTCRfi") && (
              <PaymentContainer
                locationId={query.get("location")}
                businessId={query.get("business")}
                vehicleId={currentVehicle.id}
                card={query.get("card")}
              />
            )}
        </Grid>
        <Grid item xs={12} sm={12} style={{ paddingLeft: "20px" }}>
          {currentVehicle &&
            (query.get("business") === "idbusiness_2" ||
              query.get("business") === "kmq9dvl0EYjyhHPTCRfi") &&
            currentVehicle.billing && (
              <BillingConfirmation billingData={currentVehicle.billing} />
            )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            paddingTop: 10,
            paddingBottom: 20,
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ color: textColor, textAlign: "center", marginTop: 30 }}
          >
            {texts.poweredBy}
          </Typography>

          <img
            src="https://firebasestorage.googleapis.com/v0/b/softicade.appspot.com/o/Logo%2FlogoWords.png?alt=media&token=63bf2d1c-0203-4b2b-98ea-03667c533fca"
            alt="logo"
            height={50}
          />
        </Grid>
      </Grid>
    </div>
  );
}
